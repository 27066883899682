import React, { useState } from 'react';


// Dropdown component definition

const Dropdown = ({ onDataUpdate, SpinnerLoading, userID }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const value = event === "1" ? "1" : event.target.value;
    const trueEvent = stringToNumber(value);
    setSelectedOption(trueEvent);
    getVideoFromClass(trueEvent); // Fetch video with selected class
  };

  function stringToNumber(string) {
    switch (string) {
      case "1":
        return 1;
      case "2":
        return 2;
      case "3":
        return 3;
      default:
        return 0;
    }
  }

  // Fetch video data
  async function getVideoFromClass(classE) {
    if (!userID) {
      console.error("UserID is missing, cannot fetch video data.");
      return;
    }

    setLoading(true);
    SpinnerLoading(true, "New video");

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL_BACKEND}/getVideoFromClass`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ classE, userID }),
      });

      const data = await response.json();
      await onDataUpdate(data);
    } catch (error) {
      console.error('Error fetching video data:', error);
    } finally {
      setTimeout(() => {
        SpinnerLoading(false, "New video");
      }, 4000);
    }
  }

  return (
    <div className="dropdown-container">
      <label className="dropdown-label" htmlFor="video-select">Select a video: </label>
      <select id="video-select" className="dropdown-select" value={selectedOption} onChange={handleChange}>
        <option value="">Select an option</option>
        <option value="1">Reduced</option>
        <option value="2">Normal</option>
        <option value="3">Abnormal</option>
      </select>
      {loading}
    </div>
  );
};

export default Dropdown;
