import React from 'react';
import './css/SourcesCreditsPage.css';

/* Sources and Credits page */

// Lists of developers, sources and credits data to be displayed on the Sources and Credits page
const SourcesCreditsPage = () => {

  // Developers data
  const developers = [
    {
      photo: require("../images/dev_pictures/emilie_clary.jpg"),
      name: "Emilie CLARY",
      description:  "As a student from JUNIA ISEN Lille in FRANCE, Emilie worked 3 months on the Echo Explore project. She entirely did the backend part of the project.",
      linkedin: "https://www.linkedin.com/in/emilie-clary/",
    },
    {
      photo: require("../images/dev_pictures/gabin_dietsch.png"),
      name: "Gabin DIETSCH",
      description: "A student from JUNIA ISEN in Lille FRANCE who worked 3 months on the Echo Explore project",
      linkedin: "https://www.linkedin.com/in/gabin-dietsch/",
    },
    {
      photo: require("../images/dev_pictures/antoine_vanberten.jpg"),
      name: "Antoine VANBERTEN",
      description: "French student from JUNIA ISEN Lille who worked 3 months mainly on the front-end part of the Echo Explore project.",
      linkedin: "https://www.linkedin.com/in/antoine-vanberten/",
    },
    {
      photo: require("../images/dev_pictures/cyril_dhoudetot.png"),
      name: "Cyril D'HOUDETOT",
      description: "French student from IG2I Centrale Lille who worked 4 months mainly on fixing bug and added the multi-user service of the Echo Explore project.",
      linkedin: "https://www.linkedin.com/in/cyril-d-houdetot-849722209/",
    },
  ];
  
  // Sources data
  const sources = [
    {
      title: "EchoNet-Dynamic Dataset",
      description: "The EchoNet-Dynamic dataset is a large-scale dataset of echocardiograms with corresponding expert annotations. The dataset is designed to support the development of machine learning models for the analysis of echocardiograms. The dataset includes 10,030 echocardiograms from 11 different clinical sites, with expert annotations for 13 different view planes. The dataset is available for download from the EchoNet website.",
      link: "https://echonet.github.io/dynamic/index.html"
    },
    //{
    //  title: "BRISQUE",
    //  description: "BRISQUE (Blind/Referenceless Image Spatial Quality Evaluator) is a no-reference image quality assessment algorithm that is used to measure the quality of images without a reference image. The algorithm is based on natural scene statistics and is designed to predict the perceptual quality of images.",
    //  link: "https://learnopencv.com/image-quality-assessment-brisque/"
    //}
  ];

  // Credits data
  const credits = [
    {
      title: "Johannes Gutenberg University Mainz",
      description: "The Johannes Gutenberg University Mainz is a public research university in Mainz, Rhineland Palatinate, Germany. It was founded in 1477 and is one of the oldest universities in Germany. The university is a member of the German U15, a coalition of fifteen research-intensive and leading universities in Germany.",
      link: "https://www.uni-mainz.de"
    },
    {
      title: "Hakan LANE",
      description: "Hakan LANE is a researcher at the Johannes Gutenberg University Mainz. He is the main founder of the Nightingale project and without him, the project would not have been possible.",
      link: "https://www.linkedin.com/in/h%C3%A5kan-lane-5baa57227/"
    },
  ];

  return (
    <div className="sources-credits-page">
      {/* List of the developers */}
      <div className="dev-creds">
        <h2>Developers of the Echo-Explore service</h2>
        <hr className="hr-title"></hr>
        <div className="devs-container">
          {/* Display the developers' information from the list */}
          {developers.map((developer, index) => (
            <div key={index} className="dev-box">
              <img src={developer.photo} className="dev-box-picture" alt="developer" />
              <h2>{developer.name}</h2>
              <p>{developer.description}</p>
              <a href={developer.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
          ))}
        </div>
      </div>

      <div className="src-creds">
        {/* List of the sources and credits */}
        <h2>The list of all the sources and credits</h2>
        <hr className="hr-title-sc"></hr>
        <div className="sources-credits-container">
          <div className="sources-list">
            {/* Display the sources' information from the sources list */}
            {sources.map((source, index) => (
              <div key={index} className="source-box">
                <h2>{source.title}</h2>
                <p>{source.description}</p>
                {source.link && <a href={source.link} target="_blank" rel="noopener noreferrer">Learn More</a>}
              </div>
            ))}
          </div>
          <div className="credits-list">
            {/* Display the credits' information from the credits list */}
            {credits.map((credit, index) => (
              <div key={index} className="credit-box">
                <h2>{credit.title}</h2>
                <p>{credit.description}</p>
                {credit.link && <a href={credit.link} target="_blank" rel="noopener noreferrer">Learn More</a>}
              </div>
            ))}
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default SourcesCreditsPage;